






























import { defineComponent } from '@vue/composition-api';

import WatermarkOverlay from '@/components/WatermarkOverlay.vue';

export default defineComponent({
  name: 'FireProtectionEducation',
  // props: {
  // },
  components: {
    WatermarkOverlay
  },
  setup: () => {
    return {};
  }
});
